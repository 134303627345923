.timeline-container {
  position: absolute;
  left: 300px;
  bottom: 10px;
  height: 50px;
  z-index: 99;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 740px) and (max-width: 1099px) {
  .timeline-container {
    width: calc(100% - 340px);
  }
}
@media (min-width: 1100px) {
  .timeline-container {
    width: calc(100% - 340px);
  }
}
@media (min-width: 1700px) {
  .timeline-container {
    left: 340px;
    width: calc(100% - 380px);
  }
}
@media (min-width: 740px) and (max-width: 1099px) {
  .show-pov .timeline-container {
    width: calc(100% - 730px);
  }
}
@media (min-width: 1100px) {
  .show-pov .timeline-container {
    width: calc(100% - 710px);
  }
}
@media (min-width: 1700px) {
  .show-pov .timeline-container {
    left: 340px;
    width: calc(100% - 780px);
  }
}
.timeline-container .left {
  width: 50px;
}
.timeline-container .right {
  width: calc(100% - 60px);
  margin-left: 10px;
  padding-top: 15px;
}
.timeline-container .btn-tl {
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition: all 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: box-shadow;
  cursor: pointer;
}
@media (min-width: 1100px) {
  .timeline-container .btn-tl:hover {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 12px;
  }
}
.timeline-container .btn-tl svg {
  margin-left: 2px;
}
.timeline-container .btn-tl path {
  fill: #0066ec;
}
.timeline-container .btn-tl .pause {
  display: none;
}
.timeline-container .btn-tl.pause .pause {
  display: block;
}
.timeline-container .btn-tl.pause .play {
  display: none;
}
.timeline-container .tl-bar {
  background-color: #ccc;
  background: linear-gradient(to right, #afafaf, #6e6d6d);
  border-radius: 10px;
  height: 6px;
  position: relative;
  z-index: 3;
}
.timeline-container .tl-bar .drag-bar {
  background-color: #0066ec;
  border-radius: 10px;
  height: 100%;
  position: absolute;
  background: linear-gradient(to right, #0066ec, #539dff);
}
.timeline-container .keypoint {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 50%;
  transform: translate(-50%, -50%);
}
.timeline-container .keypoint:before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 100%;
  background-color: #0066ec;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 50%;
  transition: all 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: transform;
}
.timeline-container .keypoint .time {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -110%);
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: transform, background-color, padding, box-shadow;
  overflow: hidden;
  padding: 4px 6px;
  border-radius: 6px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.timeline-container .keypoint .time span {
  font-size: 11px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  line-height: 1.25em;
  display: block;
  width: 100%;
  text-align: center;
  transition: font-size 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.timeline-container .keypoint .time span.bold {
  font-weight: bold;
  color: #0066ec;
}
@media (min-width: 1100px) {
  .timeline-container .keypoint:hover {
    cursor: grab;
  }
  .timeline-container .keypoint:hover:before {
    transform: scale(2);
  }
  .timeline-container .keypoint:hover .time {
    transform: translate(-50%, -130%);
    background-color: rgba(255, 255, 255, 0.85);
    padding: 5px 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .timeline-container .keypoint:hover .time span {
    font-size: 14px;
  }
}
.timeline-container .tl-info {
  display: flex;
  padding: 0 10px;
  position: relative;
  width: 100%;
  z-index: 1;
  margin-top: -2px;
}
.timeline-container .tl-info > div {
  border-left: 1px solid #666;
  font-size: 12px;
  font-weight: bold;
  line-height: 1em;
  padding: 10px 4px 0;
  position: absolute;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  top: 6px;
}
.timeline-container .tl-info > div.start {
  left: 2px;
}
.timeline-container .tl-info > div.end {
  right: 2px;
  border-left: 0;
  border-right: 1px solid #666;
}
.tm-mode .timeline-container .btn-tl {
  background-color: #e9f0f9;
}
.tm-mode .timeline-container .btn-tl path {
  fill: #00275a;
}
.tm-mode .timeline-container .tl-bar {
  background-color: #ccc;
  background: linear-gradient(to right, #a0adbe, #6c7581);
}
.tm-mode .timeline-container .tl-bar .drag-bar {
  background: linear-gradient(to right, #00275a, #0053c0);
}
.tm-mode .timeline-container .keypoint {
  background-color: #00275a;
}
.tm-mode .timeline-container .tl-info > div {
  border-left: 1px solid #666;
}
