.project-list-container > :not(:first-of-type) {
  margin-top: 0.2em;
}

.new-project-form > :not(:first-of-type) {
  margin-top: 1em;
}

h2 {
  color: "#999";
}

.block-cell-container:hover .block-cell-normal {
  display: none;
}

.block-cell-container:not(:hover) .block-cell-hover {
  display: none;
}
